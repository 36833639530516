import React, { Component } from "react";

class AnswerSingle extends Component {
  state = { answer: this.props.answer, selected: false, mandatory: true };

  handleInputChange = event => {
    this.props.onNameChange(this.state.answer.id, event.target.value);
    this.setState({ selected: true });
  };

  render() {
    const { answer: answer, selected: selected } = this.props;

    return (
      <div className="answer-item form-check">
        <input
          className="form-check-input"
          type="checkbox"
          name="flexRadioDefault"
          id={"answer-input-" + answer.id}
          onChange={this.handleInputChange}
          value={answer.title}
        />
        <label
          className="form-check-label"
          htmlFor={"answer-input-" + answer.id}
        >
          <div className="answer-single-text">
            {!selected ? (
              <i className="far fa-circle"></i>
            ) : (
              <i className="fas fa-check-circle"></i>
            )}
            <span className="answer-item-text">{answer.title}</span>
          </div>
        </label>
      </div>
    );
  }
}

export default AnswerSingle;
