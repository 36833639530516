import React, { Component } from "react";
import QuestionState from "./questionState";
import AnswerRatingHeader from "./answerRatingHeader";
import { Table } from "react-bootstrap";
import AnswerSingle from "./answerSingle";
import AnswerMultiple from "./answerMultiple";
import AnswerFreeText from "./answerFreeText";
import AnswerRating from "./answerRating";
import AnswerSingleText from "./answerSingleText";
import AnswerRatingRangeSlider from "./answerRatingRangeSlider";
import $ from "jquery";

class Question extends Component {
  state = {
    question: this.props.question,
    answerTitle: "Not answered",
    resultText: "",
    answered: false,
    firstQuestion: this.props.firstQuestion,
    evaluationRequested: false,
    mandatory: null
  };

  handleNameChange = (answerID, answerTitle) => {
    this.setState({ answered: true });
    this.setState({ answerTitle });
    this.updateUserAnswerQuestionLvl(answerID, answerTitle, "");
  };

  handleNameTextChange = (answerID, answerTitle, answerText) => {
    this.setState({ answered: true });
    this.setState({ answerTitle });
    this.setState({ answerText });
    this.updateUserAnswerQuestionLvl(answerID, answerTitle, answerText);
  };

  updateUserAnswerQuestionLvl = (answerID, answerTitle, answerText) => {
    this.props.updateUserAnswer(
      this.state.question.questionset_id,
      this.state.question.id,
      answerID,
      answerTitle,
      answerText
    );
  };

  isMobileActiveInternal() {
    if ($("body")[0].clientWidth < 992) {
      return true;
    } else {
      return false;
    }
  }

  isMobileRatingActiveInternal() {
    if ($("body")[0].clientWidth < 1200) {
      return true;
    } else {
      return false;
    }
  }

  isMobileRatingSMActiveInternal() {
    if ($("body")[0].clientWidth < 576) {
      return true;
    } else {
      return false;
    }
  }

  checkMandatoryState(mandatory) {
    if (this.state.mandatory == null) {
      this.setState({ mandatory: mandatory });

      if (mandatory) {
        this.props.updateUserAnswer(
          this.state.question.questionset_id,
          this.state.question.id,
          null,
          null,
          null
        );
      }
    }
  }

  render() {
    if (this.state.mandatory == null) {
      this.checkMandatoryState(this.props.question.mandatory);
    }

    const {
      question: question,
      answerTitle: name,
      answered: answered,
      firstQuestion: firstQuestion,
      mandatory: mandatory
    } = this.state;

    const { evaluationRequested: evaluationRequested } = this.props;

    const mobileView = this.isMobileActiveInternal();
    const mobileRatingView = this.isMobileRatingActiveInternal();
    const mobileRatingSMView = this.isMobileRatingSMActiveInternal();

    switch (question.questiontype_id) {
      case 1:
        return (
          <div
            className={
              evaluationRequested && mandatory && !answered
                ? "question-item question-single question-item-missing"
                : "question-item question-single"
            }
          >
            <div className="questionTitle">{question.title}</div>
            {question.answers.map(answer => (
              <AnswerSingle
                answer={answer}
                onNameChange={this.handleNameChange}
                selected={name == answer.title}
              />
            ))}
          </div>
        );
      case 2:
        return (
          <div
            className={
              evaluationRequested && mandatory && !answered
                ? "question-item question-multi question-item-missing"
                : "question-item question-multi"
            }
          >
            <div className="questionTitle">{question.title}</div>
            {question.answers.map(answer => (
              <AnswerMultiple
                answer={answer}
                onNameChange={this.handleNameChange}
                selected={name == answer.title}
              />
            ))}
          </div>
        );
      case 4:
        return (
          <div
            className={
              evaluationRequested && mandatory && !answered
                ? "question-item question-freetext question-item-missing"
                : "question-item question-freetext"
            }
          >
            <div className="questionTitle">{question.title}</div>
            <AnswerFreeText onNameChange={this.handleNameTextChange} />
          </div>
        );
      case 6:
        return (
          <div
            className={
              evaluationRequested && mandatory && !answered
                ? "question-item question-item-missing"
                : "question-item "
            }
          >
            {mobileView ? (
              <div className="mobile-top">
                <div className="questionTitle mobile-title-tmp">
                  {question.title}
                </div>
                {mobileRatingSMView ? (
                  ""
                ) : (
                  <QuestionState
                    question={question}
                    name={name}
                    answered={answered}
                  />
                )}
              </div>
            ) : (
              ""
            )}
            {mobileRatingSMView ? (
              <div className="answer-rating-sm">
                {question.answers.map(answer => (
                  <AnswerSingle
                    answer={answer}
                    onNameChange={this.handleNameChange}
                    selected={name == answer.title}
                    mobileView={mobileView}
                  />
                ))}
              </div>
            ) : (
              <Table>
                {firstQuestion || mobileView || question.id == 29831 ? ( // TODO: well fix that......
                  <AnswerRatingHeader
                    question={question}
                    mobileView={mobileView}
                    mobileRatingView={mobileRatingView}
                  />
                ) : (
                  ""
                )}
                <tbody>
                  <tr>
                    {mobileView ? (
                      ""
                    ) : (
                      <td
                        width={mobileRatingView ? "50%" : "41.67%"}
                        className="questionTitle"
                      >
                        {question.title}
                      </td>
                    )}
                    {mobileView || mobileRatingView ? (
                      ""
                    ) : (
                      <td width="16.67%" className="questionState">
                        <QuestionState
                          question={question}
                          name={name}
                          answered={answered}
                        />
                      </td>
                    )}
                    {question.answers.map(answer => (
                      <AnswerRating
                        answer={answer}
                        onNameChange={this.handleNameChange}
                        selected={name == answer.title}
                        mobileView={mobileView}
                      />
                    ))}
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        );
      case 8:
        return (
          <div
            className={
              evaluationRequested && mandatory && !answered
                ? "question-item question-item-missing"
                : "question-item "
            }
          >
            {mobileView ? (
              <div className="questionTitle mobile-title-tmp">
                {question.title}
              </div>
            ) : (
              ""
            )}
            {mobileRatingSMView ? (
              <div className="answer-rating-sm">
                {question.answers.map(answer => (
                  <AnswerSingle
                    answer={answer}
                    onNameChange={this.handleNameChange}
                    selected={name == answer.title}
                    mobileView={mobileView}
                  />
                ))}
              </div>
            ) : (
              <Table>
                <AnswerRatingHeader
                  question={question}
                  mobileView={mobileView}
                  mobileRatingView={mobileRatingView}
                />
                <tbody>
                  <tr>
                    {mobileView ? (
                      ""
                    ) : (
                      <td width="41.67%" className="questionTitle">
                        {question.title}
                      </td>
                    )}
                    {mobileView || mobileRatingView ? (
                      ""
                    ) : (
                      <td width="16.67%" className="questionState">
                        <QuestionState
                          question={question}
                          name={name}
                          answered={answered}
                        />
                      </td>
                    )}
                    <AnswerRatingRangeSlider
                      columnCount={question.answers.length}
                      answers={question.answers}
                      onNameChange={this.handleNameChange}
                    />
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        );
      case 7:
        return (
          <div
            className={
              evaluationRequested && mandatory && !answered
                ? "question-item question-singletext question-item-missing"
                : "question-item question-singletext"
            }
          >
            <div className="questionTitle">{question.title}</div>
            {question.answers.map(answer => (
              <AnswerSingleText
                answer={answer}
                onNameChange={this.handleNameTextChange}
                selected={name == answer.title}
              />
            ))}
          </div>
        );
    }
    return "";
  }
}

export default Question;
