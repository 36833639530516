import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "jquery/dist/jquery.js";
import Evaluation from "./components/evaluation";
import "moment/dist/moment";
import "./index.css";
// import "./css/fontawesome-free-5.2.0-web/css/all.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

window.evaluationContainer = (function() {
  const jmeInstances = {};

  const openEvaluation = (elemID, userID, eventID) => {
    checkMountEvaluation(elemID, "");
    ReactDOM.render(
      <React.StrictMode>
        <Evaluation userID={userID} eventID={eventID} />
      </React.StrictMode>,
      document.getElementById(elemID)
      // document.getElementById(elemID)
    );
  };

  const checkMountEvaluation = (elemID, test) => {
    const elemContainer = document.getElementById(elemID);
    if (jmeInstances[elemID]) {
      // console.log(`Removing old react component ${instanceName}`)
      try {
        ReactDOM.unmountComponentAtNode(jmeInstances[elemID]);
      } catch (error) {}
    }
    // To remember if needs to clean the element
    if (elemContainer) {
      jmeInstances[elemID] = elemContainer;
    }
  };

  return {
    openEvaluation,
    checkMountEvaluation
  };
})();

console.log("JME Evaluation: Init finished");

// window.evaluationContainer.openEvaluation("root", 22699, "330");
