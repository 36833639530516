import "./Modal.css";

const Modal = ({ children, className = "container" }) => {
  return (
    <div className="ntw-modal-wrapper">
      <div className={`ntw-modal-center ${className} p-0`}>{children}</div>
    </div>
  );
};

export default Modal;
