import React, { Component } from "react";
import AnswerRatingHeaderItem from "./answerRatingHeaderItem";

class AnswerRatingHeader extends Component {
  state = { question: this.props.question };

  render() {
    const { question: question } = this.state;
    const { mobileView: mobileView } = this.props;
    const { mobileRatingView: mobileRatingView } = this.props;
    const columnWidth =
      (mobileView ? 100 : mobileRatingView ? 50 : 41.66) /
      question.answers.length;

    if (question.questiontype_id == 6 || question.questiontype_id == 8) {
      return (
        <thead>
          <tr>
            {mobileView ? (
              ""
            ) : (
              <th scope={mobileRatingView ? "col-6" : "col-5"}></th>
            )}
            {mobileView || mobileRatingView ? "" : <th scope="col-2"></th>}
            {question.answers.map(answer => (
              <AnswerRatingHeaderItem
                key={answer.id}
                answer={answer}
                columnWidth={columnWidth}
              />
            ))}
          </tr>
        </thead>
      );
    } else {
      return "";
    }
  }
}

export default AnswerRatingHeader;
