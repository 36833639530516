import React, { Component } from "react";
import moment from "moment/dist/moment.js";

class EvaluationHeader extends Component {
  state = {
    contentsession: this.props.contentsession,
    config: this.props.config
  };

  render() {
    const { contentsession: contentsession, config: config } = this.state;

    //const a = moment(contentsession.starts_at).format("MMMM DD YYYY");

    return (
      <div className="evaluation-header">
        <div className="header-banner">
          <img src={config["logo"]} className="header-banner-img" />
        </div>
        <div className="header-information">
          <div>Evaluation Form</div>
          <div>{contentsession.event.title}</div>
          <div>{contentsession.title}</div>
          {contentsession.starts_at != null &&
          contentsession.ends_at != null ? (
            <div>
              {moment(contentsession.starts_at).format("MMMM DD YYYY")}{" "}
              {moment(contentsession.starts_at).format("hh:mm")} -{" "}
              {moment(contentsession.ends_at).format("hh:mm")}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="header-additionals">{config["header-text"]}</div>
      </div>
    );
  }
}

export default EvaluationHeader;
