import React, { Component } from "react";

class QuestionState extends Component {
  state = {
    question: this.props.question,
    answered: this.props.answered,
    name: this.props.name
  };

  render() {
    const { question: question, answered: answered, name: name } = this.props;

    return (
      <div
        className={
          answered
            ? "questionStateItem questionStateItemAnswered"
            : "questionStateItem"
        }
        id={"questionStateItem" + question.id}
      >
        {name}
      </div>
    );
  }
}

export default QuestionState;
