import React, { Component } from "react";
import { Form } from "react-bootstrap";

class AnswerSingleText extends Component {
  state = { answer: this.props.answer, text: "" };

  handleInputChange = event => {
    // this.setState({ selected: true });
    this.props.onNameChange(
      this.state.answer.id,
      this.state.answer.title,
      event.target.value
    );
  };

  render() {
    const { answer: answer, selected: selected } = this.props;

    return (
      <div className="answer-item form-check">
        <input
          className="form-check-input"
          type="checkbox"
          name="flexRadioDefault"
          id={"answer-input-" + answer.id}
          onChange={this.handleInputChange}
          value={answer.title}
        />
        <label
          className="form-check-label"
          htmlFor={"answer-input-" + answer.id}
        >
          <div className="answer-singletext-text row">
            {!selected ? (
              <i className="col-auto far fa-circle"></i>
            ) : (
              <i className="col-auto fas fa-check-circle"></i>
            )}
            <span className="col answer-item-text">
              <Form.Control
                type="text"
                placeholder={answer.title}
                onChange={this.handleInputChange}
                onSelect={this.handleInputChange}
              />
            </span>
          </div>
        </label>
      </div>
    );
  }
}

export default AnswerSingleText;
