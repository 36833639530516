import React, { Component } from "react";

class AnswerRatingHeaderItem extends Component {
  state = { answer: this.props.answer, columnWidth: this.props.columnWidth };

  render() {
    const { answer: answer, columnWidth: columnWidth } = this.state;

    return (
      <th
        width={columnWidth + "%"}
        className="answer-header-item"
        id={"answerHeader" + answer.id}
      >
        {answer.title}
      </th>
    );
  }
}

export default AnswerRatingHeaderItem;
