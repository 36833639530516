import React, { Component } from "react";
import Question from "./question";

class Questionset extends Component {
  state = {
    questionSet: this.props.questionSet,
    updateUserAnswer: this.props.updateUserAnswer,
    evaluationRequested: this.props.evaluationRequested
  };

  render() {
    const {
      questionSet: questionSet,
      updateUserAnswer: updateUserAnswer
    } = this.state;

    const { evaluationRequested: evaluationRequested } = this.props;

    return (
      <div className="questionset-item">
        {questionSet.title != null && questionSet.title != "" ? (
          <div className="questionset-title col-12">{questionSet.title}</div>
        ) : (
          ""
        )}

        <div className="question-list">
          {questionSet.questions.map((question, index) => (
            <Question
              key={question.id}
              question={question}
              firstQuestion={index == 0}
              updateUserAnswer={updateUserAnswer}
              evaluationRequested={evaluationRequested}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Questionset;
