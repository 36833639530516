import React, { Component } from "react";

class AnswerRating extends Component {
  state = {
    answer: this.props.answer,
    // selected: false,
    columnWidth: this.props.columnWidth
  };

  handleInputChange = event => {
    this.props.onNameChange(this.state.answer.id, event.target.value);
    // this.setState({ selected: true });
  };

  render() {
    const {
      answer: answer,
      selected: selected,
      columnWidth: columnWidth
    } = this.props;

    return (
      <td className="answer-rating" width={columnWidth + "%"}>
        <div className="answer-item form-check">
          <input
            className="form-check-input"
            type="radio"
            name={"flexRadioDefault" + answer.question_id}
            id={"answer-input-" + answer.id}
            onChange={this.handleInputChange}
            value={answer.title}
          />

          <label
            className="form-check-label"
            htmlFor={"answer-input-" + answer.id}
          >
            {!selected ? (
              <i className="far fa-circle"></i>
            ) : (
              <i className="fas fa-check-circle"></i>
            )}
          </label>
        </div>
      </td>
    );
  }
}

export default AnswerRating;
