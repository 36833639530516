import React, { Component } from "react";

class EvaluationFooter extends Component {
  state = {
    contentsession: this.props.contentsession,
    config: this.props.config
  };

  render() {
    const { contentsession: contentsession, config: config } = this.state;

    //const a = moment(contentsession.starts_at).format("MMMM DD YYYY");

    return (
      <div className="evaluation-footer">
        <div>{config["footer-text"]}</div>
      </div>
    );
  }
}

export default EvaluationFooter;
