import React, { Component } from "react";
import { Form } from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";

class AnswerRatingRangeSlider extends Component {
  state = {
    answers: this.props.answers,
    // selected: false,
    // columnWidth: this.props.columnWidth,
    columnCount: this.props.columnCount
  };

  handleInputChange = event => {
    this.props.onNameChange(
      this.state.answers[event.target.value].id,
      this.state.answers[event.target.value].title
    );
  };

  render() {
    const {
      // selected: selected,
      // columnWidth: columnWidth,
      columnCount: columnCount
    } = this.props;

    return (
      <td className="answer-rating-slider" colSpan={columnCount.toString()}>
        <Form className="answer-rating-slider-form">
          <Form.Group controlId="formBasicRangeCustom">
            <RangeSlider
              type="range"
              min={0}
              max={columnCount - 1}
              onChange={this.handleInputChange}
              tooltip="off"
              variant="dark"
            />
          </Form.Group>
        </Form>
      </td>
    );
  }
}

export default AnswerRatingRangeSlider;
