import React, { Component } from "react";
import Questionset from "./questionset";
import EvaluationHeader from "./evaluationHeader";
import EvaluationFooter from "./evaluationFooter";
import EvaluationDoneModal from "./evaluationDoneModal";
import { Base64 } from "js-base64";

class Evaluation extends Component {
  state = {
    qsIsLoaded: false,
    configIsLoaded: false,
    config: 0,
    questionSets: [],
    showModal: false,
    userAnswers: {},
    evaluationRequested: false,
    evaluationDone: false,
    evaluationError: false,
    evaluationErrorState: null,
    evaluationValid: false,
    user_id: this.props.userID,
    event_id: this.props.eventID
  };

  componentDidMount() {
    this.getQuestionsetData();
    this.getConfigData();
  }

  getQuestionsetData() {
    var params = {};
    var currentURL = window.location.href;
    var paramArray = currentURL.split("?");
    if (paramArray.length == 2) {
      var tmpArray = paramArray[1].split("&");
      for (var i = 0; i < tmpArray.length; i++) {
        var param = tmpArray[i];
        var array = param.split("=");
        if (array[1] != "*") {
          params[array[0]] = array[1];
        }
      }
    }

    if (params["cid"] != undefined) {
      if (this.state.user_id == null && params["user_id"] != undefined) {
        this.setState({ user_id: params["user_id"] });
      }

      fetch(
        "https://api-lb.virtual-meeting.net/v1/questionsets.json?questionsettype_id=5&event_id=" +
          this.state.event_id +
          "&contentsession_id=" +
          params["cid"],
        // Base64.decode(params["cid"]),
        {
          headers: {
            Authorization: "Basic m-events:kims"
          }
        }
      )
        .then(res => res.json())
        .then(result => {
          console.log(result);
          this.setState({ qsIsLoaded: true, questionSets: result });
        });
    }
  }

  sendQuestionsetData(
    questionset_id,
    contentsession_id,
    evaluation_data,
    evaluation_user_id
  ) {
    const body_obj = {};
    body_obj["questionset_id"] = questionset_id;
    // body_obj["contentsession_id"] = contentsession_id;
    body_obj["evaluation_data"] = evaluation_data;
    if (evaluation_user_id != null) {
      body_obj["evaluation_user_id"] = evaluation_user_id;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic m-events:kims"
      },
      body: JSON.stringify(body_obj)
    };
    fetch(
      "https://api-lb.virtual-meeting.net/v1/questionsets/evaluation.json",
      requestOptions
    )
      .then(res => res.json())
      .then(result => {
        if (
          result == null ||
          result["error_message"] == undefined ||
          result["error_message"] == ""
        ) {
        } else {
          return Promise.reject(result);
        }
      })
      .catch(error => {
        this.setState({ evaluationError: true });

        var errorMessage =
          "Unfortunately an error occurred. If the issue persists, please contact our helpdesk.";
        if (error["error_message"] == "Evaluation already done") {
          errorMessage = "You did already submit this evaluation.";
        }
        this.setState({
          evaluationErrorState: errorMessage
        });

        console.error("There was an error!", error);
      });
  }

  getSessionForQuestionset(questionset_id) {
    var i = 0;
    while (i < this.state.questionSets.length) {
      const item = this.state.questionSets[i];
      if (item.id == questionset_id || item.id + "" == questionset_id) {
        return item.contentsession_id;
      }
      i = i + 1;
    }
    return null;
  }

  getConfigData() {
    fetch(
      "https://mevents-api-uploads.s3.amazonaws.com/api_uploads/evaluation/config.json"
    )
      .then(res => res.json())
      .then(result => {
        this.setState({ configIsLoaded: true, config: result });
      });
  }

  closeModal = e => {
    this.setState({ showModal: false });
  };

  submitEvaluation() {
    // Check if mandatory questions are answered
    for (var key in this.state.userAnswers) {
      for (var key2 in this.state.userAnswers[key]) {
        const item = this.state.userAnswers[key][key2];
        console.log(key + "(" + key2 + ") : " + item);
        if (item == [] || item[0] == null) {
          this.setState({
            showModal: true,
            evaluationRequested: true,
            evaluationValid: false
          });
          return;
        }
      }
    }
    // Create submit jsons (one call for each qs in the eval...) TODO Change API Endpoint

    this.setState({ evaluationError: false });

    for (var key in this.state.userAnswers) {
      const questions = {};

      for (var key2 in this.state.userAnswers[key]) {
        const answer_id = this.state.userAnswers[key][key2][0] + "";

        if (answer_id == -1 || answer_id == "-1") {
          questions[key2] = [this.state.userAnswers[key][key2][2]];
        } else {
          if (this.state.userAnswers[key][key2][2] == "") {
            questions[key2] = [this.state.userAnswers[key][key2][0] + ""];
          } else {
            questions[key2] = [
              this.state.userAnswers[key][key2][0] + "",
              this.state.userAnswers[key][key2][2]
            ];
          }
        }
      }

      const evaluation_data = {};
      evaluation_data["questionsetid"] = key;
      evaluation_data["questions"] = questions;

      this.sendQuestionsetData(
        key,
        this.getSessionForQuestionset(key),
        JSON.stringify(evaluation_data),
        this.state.user_id
      );
    }

    this.setState({
      showModal: true,
      evaluationRequested: true,
      evaluationValid: true,
      evaluationDone: true
    });
  }

  updateUserAnswer = (
    questionsetID,
    questionID,
    answerID,
    answerTitle,
    answerText
  ) => {
    var userAnswers = this.state.userAnswers;

    if (userAnswers[questionsetID] == null) {
      userAnswers[questionsetID] = {};
      userAnswers[questionsetID][questionID] = [
        answerID,
        answerTitle,
        answerText
      ];
    } else {
      userAnswers[questionsetID][questionID] = [
        answerID,
        answerTitle,
        answerText
      ];
    }
    this.setState({ userAnswers: userAnswers });
  };

  render() {
    const {
      qsIsLoaded,
      configIsLoaded,
      questionSets: questionSets,
      config: config,
      showModal: showModal,
      evaluationRequested: evaluationRequested,
      evaluationValid: evaluationValid,
      evaluationDone: evaluationDone,
      evaluationError: evaluationError,
      evaluationErrorState: evaluationErrorState
    } = this.state;

    if (!qsIsLoaded || !configIsLoaded) {
      return <div> Loading ...... </div>;
    }

    return (
      <div id="evaluation-content" className="container">
        {showModal ? (
          <EvaluationDoneModal
            config={config}
            closeModal={this.closeModal}
            evaluationValid={evaluationValid}
            evaluationDone={evaluationDone}
            evaluationError={evaluationError}
            evaluationErrorState={evaluationErrorState}
          />
        ) : (
          ""
        )}

        <EvaluationHeader
          contentsession={questionSets[0].contentsession}
          config={config}
        />

        <div className="evaluation-questionset-list">
          {questionSets.map(questionSet => (
            <Questionset
              key={questionSet.id}
              questionSet={questionSet}
              updateUserAnswer={this.updateUserAnswer}
              evaluationRequested={evaluationRequested}
            />
          ))}
        </div>

        <div className="evaluation-questionset-submit">
          <button
            type="button"
            className="btn btn-outline-dark evaluation-submit-button"
            onClick={() => this.submitEvaluation()}
          >
            Submit Evaluation
          </button>
        </div>
        <EvaluationFooter
          contentsession={questionSets[0].contentsession}
          config={config}
        />
      </div>
    );
  }
}

export default Evaluation;
