import React, { Component } from "react";

class AnswerFreeText extends Component {
  handleInputChange = event => {
    this.props.onNameChange(-1, "", event.target.value);
  };

  render() {
    return (
      <div className="answer-item">
        <div className="form-group">
          <textarea
            className="form-control"
            htmlFor="exampleFormControlTextarea1"
            rows="3"
            placeholder="Please add your answer here..."
            onBlur={this.handleInputChange}
          ></textarea>
        </div>
      </div>
    );
  }
}

export default AnswerFreeText;
