import React, { Component } from "react";
import Modal from "./Modal";

class EvaluationDoneModal extends Component {
  state = { config: this.props.config };

  handleClick = event => {
    this.props.closeModal(event.target.value);
  };

  handleRedirect = event => {
    window.location.href = this.state.config["evaluation-done-link-continue"];
  };

  render() {
    const { config: config } = this.state;
    const {
      evaluationValid: evaluationValid,
      evaluationDone: evaluationDone,
      evaluationError: evaluationError,
      evaluationErrorState: evaluationErrorState
    } = this.props;

    return (
      <Modal>
        <div className="ntw-confirm-popup-btn-times">
          <i
            className="fas fa-times-circle"
            color={"black"}
            size={30}
            onClick={e => {
              this.handleClick(e);
            }}
          />
        </div>
        <div className="ntw-confirm-popup-title">
          {config["evaluation-done-header"]}
        </div>
        <div className="evaluation-done-text-container">
          <div className="d-flex flex-column align-items-center flex-fill p-3 text-center">
            <div className="d-flex justify-content-center w-100">
              <div className="col-12 col-md-9">
                <p className="ntw-modal-subtitle ntw-bold">
                  {evaluationValid && evaluationDone
                    ? evaluationError
                      ? evaluationErrorState
                      : config["evaluation-done-text"]
                    : config["evaluation-done-text-missing"]}
                </p>
                {evaluationValid ? (
                  <p className="ntw-modal-text ntw-bold">
                    {config["evaluation-done-text-continue"]}
                  </p>
                ) : (
                  <p className="ntw-modal-text ntw-bold">
                    {config["evaluation-done-text-close"]}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="evaluation-done-footer">
          {evaluationError || evaluationValid ? (
            ""
          ) : (
            <button
              className="ntw-btn ntw-white ntw-popup-blue ntw-popup-btn"
              onClick={e => {
                this.handleClick(e);
              }}
            >
              <span className="evaluation-done-button-label">
                {config["evaluation-done-button-close"]}
              </span>
              <i className="fa fa-arrow-circle-right evaluation-done-button-icon"></i>
            </button>
          )}
          {evaluationValid ? (
            <button
              className="ntw-btn ntw-white ntw-popup-blue ntw-popup-btn"
              onClick={e => {
                this.handleRedirect(e);
              }}
            >
              <span className="evaluation-done-button-label">
                {config["evaluation-done-button-continue"]}
              </span>
              <i className="fa fa-arrow-circle-right evaluation-done-button-icon"></i>
            </button>
          ) : (
            ""
          )}
        </div>
      </Modal>
    );
  }
}

export default EvaluationDoneModal;
